<template>
    <v-container style="min-height: 100vh;">
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title class="pt-2" ref="orders">
                        <v-text-field
                                v-model="search"
                                append-icon="search"
                                :label="$vuetify.lang.t('$vuetify.searchLabel')"
                                single-line
                                hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                            :headers="headers"
                            :items="orders"
                            :search="search"
                            hide-default-footer
                    >

                        <template v-slot:item.products="{ item }">
                            <td class="px-0" style="border: none;">
                                <div class="text--secondary" :class="{'py-2 ': item.products.length > 1 }"
                                     v-for="(el) in item.products" :key="el.title">
                                    {{ el.title }}
                                    <v-icon x-small>mdi-close</v-icon>
                                    {{el.quantity}}
                                </div>
                            </td>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <div class="text-center my-8">
            <v-pagination
                    v-model="page"
                    :length="total"
            ></v-pagination>
        </div>
    </v-container>
</template>

<script>
    // import {mapGetters} from 'vuex'
    export default {
        data() {
            return {
                // loading: true,
                search: '',
                headers: [
                    // {
                    //     text: this.$vuetify.lang.t('$vuetify.dateLabel'),
                    //     value: 'jdatetime',
                    //     align: 'center',
                    // },
                    // {
                    //     text: this.$vuetify.lang.t('$vuetify.timeLabel'),
                    //     value: 'datetime',
                    //     align: 'center',
                    // },
                    // {
                    //     text: this.$vuetify.lang.t('$vuetify.productLabel'),
                    //     value: 'products',
                    //     align: 'center',
                    // },
                    // {
                    //     text: this.$vuetify.lang.t('$vuetify.priceLabel'),
                    //     value: 'subtotal',
                    //     align: 'center',
                    // },
                    {text: this.$vuetify.lang.t('$vuetify.dateLabel'), value: 'date'},
                    {text: this.$vuetify.lang.t('$vuetify.timeLabel'), value: 'time'},
                    {
                        text: this.$vuetify.lang.t('$vuetify.productLabel'),
                        value: 'products',
                        sort: (a, b) => {
                            if (a.total < b.total) return -1;
                            if (a.total > b.total) return 1;
                            return 0;
                        }
                    },
                    {text: this.$vuetify.lang.t('$vuetify.priceLabel'), value: 'subtotal'},
                ],
                page: 1,
                total: 1,
                orders: []
            }
        },
        created() {
            this.getOrders();
        },
        computed: {
            // ...mapGetters({
            //     desserts: 'getOrders',
            //     page: 'page',
            //     total: 'total'
            // }),
            dateLabel() {
                return this.$vuetify.lang.t('$vuetify.dateLabel');
            },
            timeLabel() {
                return this.$vuetify.lang.t('$vuetify.timeLabel');
            },
            productLabel() {
                return this.$vuetify.lang.t('$vuetify.productLabel');
            },
            priceLabel() {
                return this.$vuetify.lang.t('$vuetify.priceLabel');
            },

        },
        methods: {
            getOrders() {
                this.$store.dispatch('getFinancialList', {page: this.page}).then((resp) => {
                    console.log('Client Orders: ', resp);
                    this.total = resp.paginate.totalPages;
                    this.orders = [];
                    resp.data.forEach(order => {
                        this.orders.push(order);
                        order['date'] = order.jdatetime.split(' ')[0];
                        order['time'] = order.jdatetime.split(' ')[1];
                        order.products.total = 0;
                        let sum = 0;
                        order.products.forEach(item => {
                            let qty = item.quantity * item.sessionQt;
                            sum += qty;
                            order.products.total = sum;
                        });
                    });

                });
            },

        },
        watch: {
            page() {
                this.getOrders();
                this.$vuetify.goTo(this.$refs.orders.offsetTop);
            }
        }
    }
</script>

<style scoped>

</style>